<template>
  <section class="mx-4 mt-10">
    <router-view :key="$route.path" />
  </section>
</template>
<script>
export default {
  name: "AdminPage",
};
</script>
